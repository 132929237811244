<template>
    <div class="white--text" dir="ltr">
    <v-row>
    <v-col cols="12" dir="rtl">
    <v-breadcrumbs :items="($vuetify.lang.current === 'he') ? breadcrumbs : breadcrumbs_en">
     <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.to"
        :disabled="item.disabled"
        :class="(!item.disabled) ? 'breadcrumbs' : ''"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    </v-breadcrumbs>
    </v-col>
    <v-col
      cols="6"
      sm="3"
       v-for="(image, imageIndex) in items"
       :key="imageIndex"
       @click="Goto(image.link)"
       class="white--text text-center"
       link
       style="cursor: pointer;"
       >
    <v-img
        :src="image.src"
        :lazy-src="image.src"
        aspect-ratio="1"
        link
        class="white--text"
      >
     <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <span v-html="image.title" class="white--text text-center"></span>
    </v-col>
        </v-row>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    </div>
</template>

<script>
import { bus } from './../main'

export default {
  data () {
    return {
      index: null,
      loadingList: true,
      title: 'טוען',
      icon: 'mdi-loading',
      color: 'orange',
      breadcrumbs: [
        {
          text: 'ראשי',
          disabled: false,
          to: '/'
        },
        {
          text: 'גלריות',
          disabled: true,
          to: '/albums'
        }
      ],
      breadcrumbs_en: [
        {
          text: 'Home',
          disabled: false,
          to: '/'
        },
        {
          text: 'Galleries',
          disabled: true,
          to: '/albums'
        }
      ],
      items: []
    }
  },
  created () {
    // window.Store.dispatch('PusherAction')
    window.Store.commit('Loader', false)
    this.GetDashboard()
    bus.$on('Open_album', (data) => {
      this.GetDashboard()
    })
  },
  methods: {
    Goto (Index) {
      this.$router.push('/gallery/' + Index)
    },
    GetDashboard () {
      window.Store.commit('Loader', true)
      let vm = this
      window.axios.get(window.API.albums + '?lang=' + this.$vuetify.lang.current)
        .then(response => {
          this.items = response.data
          window.Store.commit('Loader', false)
        })
        .catch(error => {
          vm.loadingSkeleton = false
          window.Store.commit('Loader', false)
          let errorMessage = error.response.data
          bus.$emit('Open_snackbar', 'error', errorMessage.err)
        })
    }
  }
}
</script>

<style>
.image {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}
.breadcrumbs a {
    color: #ff5722!important;
}
</style>
